import { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { TagDefinition } from '../state'
import { TagCreateForm, ColumnTypeCreateFormProps } from './form'
import { ColumnSelector, EditModal } from './selection'
import { Eye, EyeFill } from 'react-bootstrap-icons'
import { useDispatch, useSelector } from 'react-redux'
import { selectTagSelectionLoading } from '../selectors'
import { loadTagDefinitionHierarchy } from '../thunks'
import { AppDispatch } from '../../store'
import { TabView } from '../../util/components/tabs'
import { useAppSelector } from '../../hooks'

export function ColumnMenu({
    columnIndices,
    additionalEntries = [],
    additionalIndices = {},
    loadColumnDataCallback,
    hideColumnDataCallback
}: {
    columnIndices: { [key: string]: number }
    additionalEntries?: { idPersistent: string; name: string }[]
    additionalIndices?: { [key: string]: number }
    loadColumnDataCallback: (columnDefinition: TagDefinition) => void
    hideColumnDataCallback: (columnDefinition: TagDefinition) => void
}) {
    const isLoading = useSelector(selectTagSelectionLoading)
    const dispatch: AppDispatch = useDispatch()
    useEffect(
        () => {
            if (!isLoading) {
                dispatch(loadTagDefinitionHierarchy({ expand: true }))
            }
        },
        //eslint-disable-next-line
        [dispatch]
    )
    return (
        <ColumnMenuBody
            columnIndices={columnIndices}
            loadColumnDataCallback={loadColumnDataCallback}
            hideColumnDataCallback={hideColumnDataCallback}
            additionalEntries={additionalEntries}
            additionalIndices={additionalIndices}
        />
    )
}

export function ColumnMenuBody({
    columnIndices,
    additionalEntries = [],
    additionalIndices = {},
    loadColumnDataCallback,
    hideColumnDataCallback
}: {
    columnIndices: { [key: string]: number }
    additionalEntries?: { idPersistent: string; name: string }[]
    additionalIndices?: { [key: string]: number }
    loadColumnDataCallback: (columnDefinition: TagDefinition) => void
    hideColumnDataCallback: (columnDefinition: TagDefinition) => void
}) {
    const isLoading = useAppSelector(selectTagSelectionLoading)

    return (
        <>
            <TabView
                isLoading={isLoading}
                tabList={[
                    {
                        name: 'Load',
                        component: (
                            <ShowTabBody
                                additionalIndices={additionalIndices}
                                columnIndices={columnIndices}
                                loadColumnDataCallback={loadColumnDataCallback}
                                hideColumnDataCallback={hideColumnDataCallback}
                                additionalEntries={additionalEntries}
                            />
                        )
                    },
                    {
                        name: 'Create',
                        component: (
                            <CreateTabBody
                                additionalEntries={[
                                    { idPersistent: '', name: 'No parent' }
                                ]}
                            />
                        )
                    }
                ]}
            />
            <EditModal />
        </>
    )
}
export function CreateTabBody({
    additionalEntries = [],
    existingTagDefinition
}: {
    additionalEntries?: { idPersistent: string; name: string }[]
    existingTagDefinition?: TagDefinition
}) {
    return (
        <div className="d-contents overflow-hidden">
            <TagCreateForm existingTagDefinition={existingTagDefinition}>
                {(columnTypeCreateFormProps: ColumnTypeCreateFormProps) => (
                    <ColumnSelector
                        allowEdit={false}
                        additionalEntries={additionalEntries}
                        mkTailElement={(columnDefinition: TagDefinition) => (
                            <Form.Check
                                type="radio"
                                name="parent"
                                value={columnDefinition.idPersistent}
                                onChange={(_event) =>
                                    columnTypeCreateFormProps.setParent(
                                        columnDefinition.idPersistent,
                                        columnDefinition.namePath
                                    )
                                }
                                checked={
                                    columnTypeCreateFormProps.selectedParent ==
                                    columnDefinition.idPersistent
                                }
                            />
                        )}
                    />
                )}
            </TagCreateForm>
        </div>
    )
}

function ShowTabBody({
    columnIndices,
    additionalEntries,
    additionalIndices,
    loadColumnDataCallback,
    hideColumnDataCallback
}: {
    columnIndices: { [key: string]: number }
    additionalEntries: { idPersistent: string; name: string }[]
    additionalIndices: { [key: string]: number }
    loadColumnDataCallback: (columnDefinition: TagDefinition) => void
    hideColumnDataCallback: (columnDefinition: TagDefinition) => void
}) {
    return (
        <div className="ps-2 pe-2 d-contents overflow-hidden">
            <ColumnSelector
                additionalEntries={additionalEntries}
                mkTailElement={(columnDefinition: TagDefinition) => {
                    const isDisplayedInTable =
                        columnIndices[columnDefinition.idPersistent] !== undefined ||
                        additionalIndices[columnDefinition.idPersistent] !== undefined
                    if (isDisplayedInTable) {
                        return (
                            <span
                                className="icon"
                                onClick={() => hideColumnDataCallback(columnDefinition)}
                            >
                                <EyeFill height={20} />
                            </span>
                        )
                    } else {
                        return (
                            <span
                                className="icon"
                                onClick={() => loadColumnDataCallback(columnDefinition)}
                            >
                                <Eye height={20} />
                            </span>
                        )
                    }
                }}
            />
        </div>
    )
}
